
export const getAPIURL = () => {

    // console.log("Welcome React");
    // console.log(process.env.NODE_ENV);
    var environment = process.env.NODE_ENV
    // console.log("Environment : " + environment);
    // 
    const apiUrl = environment === 'development' ? 'https://api.jayalakshmi-dev.academyado.com/' : 'https://api.jayalakshmi-dev.academyado.com/';
    // const apiUrl = environment === 'development' ? 'https://api.jayalakshmi.academyado.com/' : 'https://api.jayalakshmi.academyado.com/';
    // const apiUrl = environment === 'development' ? 'http://localhost/omniwheels/bb-jayalakshmi-api/' : 'http://localhost/omniwheels/bb-jayalakshmi-api/';
    
    return apiUrl;
}

// getLicenseKey
export const getLicenseKey = 5566;