import React, { useState, useEffect } from 'react';

function Test() {
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        let interval: any;

        if (loading) {
            interval = setInterval(() => {
                setPercentage((prev) => {
                    if (prev < 100) {
                        return prev + 10;
                    } else {
                        clearInterval(interval);
                        setLoading(false);
                        return 100;
                    }
                });
            }, 500);
        }

        return () => clearInterval(interval);
    }, [loading]);

    const handleClick = () => {
        setLoading(true);
        setPercentage(0);
    };

    return (
        <div>
            <div className="text-center mg-b-10">
                <div className="spinner-border text-primary" role="status">                    
                </div>
            </div>
        </div>
    );
}

export default Test;