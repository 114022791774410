import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../contexts/authContext";
import useDarkMode from "../../hooks/useDarkMode";
import useSortableData from "../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import { useFormik } from "formik";
import { getClientList, getCurrentDateAndTime, onlyAllowNumber } from "../../services/common.service";
import { addProductHistory, getProductHistoryByUserAccountId, getProductList } from "../../services/product.service";
import { toasts } from "../../services/toaste.service";
import { getLicenseKey } from "../../services/application.settings";
import { TableLoader, showLoader } from "../../services/loader.services";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import Icon from "../icon/Icon";
import Input from "../bootstrap/forms/Input";
import NoDataMsg from "../../common/components/NoDataMsg";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import FormGroup from "../bootstrap/forms/FormGroup";
import AlertService from "../../services/alert.service";
import { useReactToPrint } from 'react-to-print';
import Collapse from "../bootstrap/Collapse";
import Select from "../bootstrap/forms/Select";
import OpenCardComponent from "../../common/components/OpenCardComponent";

function AddProduct() {

    useEffect(() => {
        getClient();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const componentRef = useRef(null);
    const [productByUserAccountData, setProductByUserAccountData] = useState<any>([])
    const [productData, setProductData] = useState<any>([])
    const [clientList, setClientList] = useState<any>([])
    const [clientInfoId, setClientInfoId] = useState<any>('')

    const [dataStatus, setDataStatus] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(productByUserAccountData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [addProductOffcanvas, setAddProductOffcanvas] = useState(false);
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    const addProductForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            productId: '',
            customerName: '',
            numberOfKnots: '',
            snoFrom: '',
            snoTo: '',
            bundleWeight: '2.300 (+ or -) 2% Kgs',
            productDate: getCurrentDateAndTime('date'),
            lotNo: '',

        },
        validate: (values: any) => {
            const errors: {
                productId?: string;
                numberOfKnots?: string;
                snoFrom?: string;
                snoTo?: string;
                bundleWeight?: string;
                productDate?: string;
                customerName?: string;
                lotNo?: string;
            } = {};
            if (!values.productId) {
                errors.productId = 'Required';
            }
            if (!values.customerName) {
                errors.customerName = 'Required';
            }
            if (!values.numberOfKnots) {
                errors.numberOfKnots = 'Required';
            }
            if (!values.snoFrom) {
                errors.snoFrom = 'Required';
            }
            if (!values.snoTo) {
                errors.snoTo = 'Required';
            }
            if (!values.bundleWeight) {
                errors.bundleWeight = 'Required';
            }
            if (!values.productDate) {
                errors.productDate = 'Required';
            }
            if (!values.lotNo) {
                errors.lotNo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addProductSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name
            i.companyName !== null && i.companyName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.place !== null && i.place.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.productName !== null && i.productName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.numberOfKnots !== null && i.numberOfKnots.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.bundleWeight !== null && i.bundleWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.snoFrom !== null && i.snoFrom.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.snoTo !== null && i.snoTo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.productDate !== null && i.productDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.customerName !== null && i.customerName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function selectedClint(e: any) {
        let clientInfoId = e.target.value
        setClientInfoId(clientInfoId)
    }

    function viewProductList() {
        setDataStatus(false)
        setIsLoader(true);
        getProduct(clientInfoId);
        getProductByUserAccount(clientInfoId)
    }

    function getProductByUserAccount(clientInfoId: any) {
        getProductHistoryByUserAccountId(clientInfoId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productHistoryByUserAccountId;
                    if (data != undefined) {
                        setProductByUserAccountData(data);
                        setDataStatus(true)
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setProductByUserAccountData([]);
                    setNoDataMsg(response.data.message)
                    setDataStatus(true);
                    setIsOpenListCard(false);
                    setIsLoader(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getProduct(clientInfoId: any) {
        getProductList(clientInfoId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productList;
                    if (data != undefined) {
                        setProductData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setProductData([{ productId: "", productName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getClient() {
        getClientList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.clientList;
                    if (data != undefined) {
                        setClientList(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setProductData([{ clientInfoId: "", clientName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


    function setProductDetails() {

        return ({
            productId: addProductForm.values.productId,
            customerName: addProductForm.values.customerName,
            numberOfKnots: addProductForm.values.numberOfKnots,
            snoFrom: addProductForm.values.snoFrom,
            snoTo: addProductForm.values.snoTo,
            bundleWeight: addProductForm.values.bundleWeight,
            productDate: addProductForm.values.productDate,
            lotNo: addProductForm.values.lotNo,
            clientInfoId: clientInfoId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addProductSubmit() {
        showLoader(true)
        if (addProductForm.isValid) {
            let addProductPostData = setProductDetails()
            addProductHistory(addProductPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getProductByUserAccount(clientInfoId);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addProductForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedProduct = i
            window.open(selectedProduct.pdfPath)
        }
    }

    function closeAndReset() {
        addProductForm.resetForm();
        // setAddProductOffcanvas(false)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Generate QR`}>
                <Page container='fluid'>

                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list' className='d-print-none'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle>
                                        Generate QR
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-4'>
                                    <div className='col-md-3'>
                                        <FormGroup id='clientInfoId' label='Client List' isFloating>
                                            <Select
                                                ariaLabel='Client List'
                                                onChange={(e: any) => selectedClint(e)}
                                                value={clientInfoId}
                                                list={clientList.map((data: any) => (
                                                    { value: data.clientInfoId, text: data.clientName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-3'>
                                        <Button className="mt-2" icon='ArrowDownward' color='primary' onClick={viewProductList} isDisable={clientInfoId > 0 ? false : true}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataStatus ?
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                    <CardTitle tag='div' className='h5'>Generate QR</CardTitle>
                                </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Button color='primary' icon='Add' isLight onClick={() => setAddProductOffcanvas(true)}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                {!dataStatus ?
                                    <TableLoader /> :
                                    <table className='table table-modern table-hover text-wrap'>
                                        <thead>
                                            <tr>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Company Name{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Place{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Customer Name{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Product Name{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Lot No{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>No Of Knots{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Bundle Weight{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>SNo From{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>SNo To{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>Product Date{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                <td />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productByUserAccountData != "" ?
                                                <>{filteredData.map((rowData: any, index: any) => (<tr key={index}>
                                                    <td>{rowData.sno}</td><td>{rowData.companyName}</td>
                                                    <td>{rowData.place}</td>
                                                    <td>{rowData.customerName}</td>
                                                    <td>{rowData.productName}</td>
                                                    <td>{rowData.lotNo}</td>
                                                    <td>{rowData.numberOfKnots}</td>
                                                    <td>{rowData.bundleWeight}</td>
                                                    <td>{rowData.snoFrom}</td>
                                                    <td>{rowData.snoTo}</td>
                                                    <td>{rowData.productDate}</td>
                                                    <td className="d-print-none"><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(rowData)}></Button></td></tr>))}</>
                                                :
                                                <NoDataMsg columnsCount={11} msg={noDataMsg} />}
                                        </tbody>
                                    </table>
                                }
                            </CardBody>

                            <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                        </Card>
                        : null}
                </Page>

                <OffCanvas setOpen={setAddProductOffcanvas} isOpen={addProductOffcanvas} titleId='addProductOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={addProductForm.handleSubmit} isNotClose>
                    <OffCanvasHeader setOpen={setAddProductOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='addProductOffcanvas'>Generate QR</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='customerName' label='Customer Name'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.customerName}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.customerName}
                                        invalidFeedback={addProductForm.errors.customerName}
                                        placeholder="Enter Customer Name" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='lotNo' label='Lot No'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.lotNo}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.lotNo}
                                        invalidFeedback={addProductForm.errors.lotNo}
                                        placeholder="Enter Lot No" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id="productId" label="Product">
                                    <Select ariaLabel="" placeholder="Select Product"
                                        onChange={addProductForm.handleChange}
                                        onBlur={addProductForm.handleBlur}
                                        value={addProductForm.values.productId}
                                        isValid={addProductForm.isValid}
                                        isTouched={addProductForm.touched.productId}
                                        invalidFeedback={addProductForm.errors.productId}
                                        list={productData.map((data: any) => (
                                            { value: data.productId, text: data.productName }
                                        ))} />
                                </FormGroup>
                                {/* <FormGroup id='productName' label='Product Name'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.productName}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.productName}
                                        invalidFeedback={addProductForm.errors.productName}
                                        placeholder="Enter Product Name" />
                                </FormGroup> */}
                            </div>
                            <div className='col-12'>
                                <FormGroup id='numberOfKnots' label='No Of Knots'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.numberOfKnots}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.numberOfKnots}
                                        invalidFeedback={addProductForm.errors.numberOfKnots}
                                        placeholder="Enter No Of Knots"
                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='bundleWeight' label='Bundle Weight'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.bundleWeight}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.bundleWeight}
                                        invalidFeedback={addProductForm.errors.bundleWeight}
                                        placeholder="Enter Bundle Weight" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='snoFrom' label='S.No From'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.snoFrom}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.snoFrom}
                                        invalidFeedback={addProductForm.errors.snoFrom}
                                        placeholder="Enter S.No From"
                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='snoTo' label='S.No To'>
                                    <Input onChange={addProductForm.handleChange} value={addProductForm.values.snoTo}
                                        isValid={addProductForm.isValid}
                                        onBlur={addProductForm.handleBlur}
                                        isTouched={addProductForm.touched.snoTo}
                                        invalidFeedback={addProductForm.errors.snoTo}
                                        placeholder="Enter S.No To"
                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <FormGroup id='productDate' label='Product Date'>
                                    <Input placeholder='Product Date'
                                        onChange={addProductForm.handleChange}
                                        onBlur={addProductForm.handleBlur}
                                        value={addProductForm.values.productDate}
                                        isValid={addProductForm.isValid}
                                        isTouched={addProductForm.touched.productDate}
                                        invalidFeedback={addProductForm.errors.productDate}
                                        type='date' />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!addProductForm.isValid && !!addProductForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper >
        </>
    )
}
export default AddProduct;